import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import Title from '../components/title/title'
import ArticleStub from '../components/article-stub/article-stub'

export default ({ data }) => (
	<Layout>
		<Title title='Articles' />

		<div>
			{data.allMdx.edges.map(({node}) => (
				<ArticleStub title={node.frontmatter.title} stub={node.frontmatter.stub} date={node.frontmatter.date} slug={`../${node.fields.slug}`}></ArticleStub>
			))}
		</div>

	</Layout>
)

export const query = graphql`query {
	allMdx(sort:{fields:[frontmatter___date], order:DESC}){
		totalCount
		edges{
			node{
		  		id
				fields {
					slug
				}
		  		frontmatter{
					title
					date(formatString: "MMMM DD, YYYY")
					stub
		  		}
				excerpt
			}
	  	}
	}
}`
