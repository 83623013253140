import React from 'react'
import styles from './article-stub.module.css'
import { Link } from 'gatsby'

export default (props) => (
	<div className={styles.articleContainer}>
		<Link to={props.slug}>
			<h3 className={styles.articleTitle}>{props.title}</h3>
		</Link>
			<span className={styles.articleDate}>{props.date}</span>
			<p className={styles.articleStub}>{props.stub}</p>
	</div>
)
