import React from 'react'
import { Helmet } from 'react-helmet'
import styles from './title.module.css'

export default (props) => (
	<section>
		<Helmet>
			<title>{props.title}</title>
		</Helmet>
		<h1 className={styles.title}>{props.title}</h1>
		<h2 className={styles.subtitle}>{props.subtitle}</h2>
	</section>
)
